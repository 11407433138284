import store from "@/store";
import axios from "axios";
import { getPathAPI } from ".";

export async function deleteClaimsDocument(
  data: Record<string, any>
): Promise<void> {
  store.commit("claims/SET_STATES", { makingApiRequest: true });
  axios({
    url: `${getPathAPI()}/storage-forms?accessToken=${localStorage.getItem(
      "access"
    )}`,
    method: "DELETE",
    data
  }).finally(() => {
    store.commit("claims/SET_STATES", { makingApiRequest: false });
  });
}

export async function restoreClaimsDocument(
  data: Record<string, any>
): Promise<void> {
  store.commit("claims/SET_STATES", { makingApiRequest: true });
  axios({
    url: `${getPathAPI()}/storage-forms/restore?accessToken=${localStorage.getItem(
      "access"
    )}`,
    method: "PUT",
    data
  }).finally(() => {
    store.commit("claims/SET_STATES", { makingApiRequest: false });
  });
}
