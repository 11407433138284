
import Vue from "vue";

export default Vue.extend({
  name: "TableColumnCounty",
  props: {
    row: {
      type: Object,
      default() {
        return {};
      }
    }
  }
});
