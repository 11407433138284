import { persistTableColumnsToLocalStorage } from "@/helpers";
import {
  ATLAS_CLAIM_REPRESENTATIVE,
  ATLAS_FIELD_ADJUSTER
} from "@/helpers/constants";
import { formatDate } from "@/helpers/dateProcessor";
import { getKindOfLossForList, policyStatus } from "@/helpers/selectOptions";
import { ITableWrapperColumns } from "@/types";
import TableColumnCounty from "@/views/shared/lossForm/ClaimsListView/TableColumnCounty.vue";
export const tableColumnsListClaims = () => {
  const columns: ITableWrapperColumns = {
    "data.lossFormRequire.clientFirstName": {
      formatDataKey: "First Name",
      formatDataValue: (arg: string) => arg,
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: true,
      allowSort: true,
      position: 0
    },
    "data.lossFormRequire.clientLastName": {
      formatDataKey: "Last Name",
      formatDataValue: (arg: string) => arg,
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: true,
      allowSort: true,
      position: 1
    },
    "data.lossFormRequire.refAgentCode": {
      formatDataKey: "Agent ID",
      formatDataValue: (arg: string) => (arg || "").toLocaleUpperCase(),
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: true,
      allowSort: true,
      position: 2,
      colWidth: 100
    },
    "data.lossFormRequire.policyNum": {
      formatDataKey: "Policy Number",
      formatDataValue: (arg: string) => arg,
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: true,
      allowSort: true,
      position: 3,
      colWidth: 140
    },
    "data.lossFormRequire.claimNum": {
      formatDataKey: "Claim Number",
      formatDataValue: (arg: string) => arg,
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: true,
      allowSort: true,
      position: 4,
      colWidth: 140
    },
    submittedOn: {
      formatDataKey: "Submitted",
      formatDataValue: (arg: string) => (arg ? formatDate(arg, true) : arg),
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: true,
      allowSort: true,
      allowInSimpleFilter: true,
      position: 5,
      colWidth: 150
    },
    status: {
      formatDataKey: "Status",
      formatDataValue: (arg: string) => arg,
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: true,
      allowSort: true,
      allowInSimpleFilter: true,
      simpleFilter: policyStatus,
      position: 6,
      colWidth: 120
    },
    "data.lossFormOtherInfo.Info.CompanyID": {
      formatDataKey: "Company Name",
      formatDataValue: (arg: string) => arg,
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: true,
      allowSort: true,
      allowInSimpleFilter: true,
      simpleFilter: policyStatus,
      position: 7,
      colWidth: 250
    },
    printed: {
      formatDataKey: "Printed",
      formatDataValue: (arg: string) => (arg ? "Yes" : "No"),
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: true,
      allowSort: true,
      allowInSimpleFilter: true,
      position: 11,
      colWidth: 100
    },
    "data.lossFormOtherInfo.FormInfo.ZipCode": {
      formatDataKey: "ZipCode",
      formatDataValue: (arg: string) => arg,
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: false,
      allowSort: true,
      allowInSimpleFilter: true,
      position: 9,
      colWidth: 110
    },
    "data.lossFormOtherInfo.FormInfo.countyCode": {
      formatDataKey: "County",
      formatDataValue: (arg: string) => arg,
      formatDataComponent: TableColumnCounty,
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: false,
      allowSort: true,
      allowInSimpleFilter: true,
      position: 10,
      colWidth: 110
    },
    "data.lossFormOtherInfo.Coverages.FieldAssigned": {
      formatDataKey: "Field Adjuster",
      formatDataValue: (arg: string) => ATLAS_FIELD_ADJUSTER[arg] || arg,
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: false,
      allowSort: true,
      allowInSimpleFilter: true,
      position: 8,
      colWidth: 110
    },
    "data.lossFormOtherInfo.Coverages.ClaimRepresentative": {
      formatDataKey: "Claim Rep",
      formatDataValue: (arg: string) => {
        const reps = ATLAS_CLAIM_REPRESENTATIVE as any;
        return reps[arg] || arg;
      },
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: false,
      allowSort: true,
      allowInSimpleFilter: true,
      position: 12,
      colWidth: 110
    },
    "data.lossFormOtherInfo.Client.PolicyNum": {
      formatDataKey: "Policy Number",
      formatDataValue: (arg: string) => arg,
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: false,
      allowSort: true,
      allowInSimpleFilter: true,
      position: 13,
      colWidth: 110
    },
    "data.lossFormOtherInfo.Coverages.WindExclude": {
      formatDataKey: "Wind Excluded",
      formatDataValue: (arg: string) => arg || "No",
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: false,
      allowSort: true,
      allowInSimpleFilter: true,
      position: 14,
      colWidth: 110
    },
    "data.lossFormOtherInfo.FormInfo.LossDate": {
      formatDataKey: "Loss Date",
      formatDataValue: (arg: string) => (arg ? formatDate(arg) : arg),
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: false,
      allowSort: true,
      allowInSimpleFilter: true,
      position: 15,
      colWidth: 110
    },
    compNum: {
      formatDataKey: "Company Number",
      formatDataValue: (arg: string) => arg,
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: false,
      allowSort: true,
      allowInSimpleFilter: true,
      position: 16,
      colWidth: 150
    },
    "data.lossFormOtherInfo.FormInfo.ClassId": {
      formatDataKey: "Loss code",
      formatDataValue: (arg: string) => `${getKindOfLossForList(arg)}`,
      allowInAdvancedFilter: true,
      allowInTableOptions: true,
      visibleByDefault: false,
      allowSort: true,
      allowInSimpleFilter: true,
      position: 17,
      colWidth: 110
    }
  };

  const tableColumns = persistTableColumnsToLocalStorage(columns, "claims");
  return tableColumns;
};
